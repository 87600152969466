import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-intl";
import * as React from "react";
import Layout from "../components/Layout";
import SEO from "../components/Seo";

interface IProps {}

const PrivacyPolicy = (props: IProps) => {
  const { formatMessage: fm } = useIntl();
  const [values, setValues] = React.useState([
    {
      title: fm({ id: "values-1" }),
      desc: fm({ id: "values-1-desc" }),
    },
    {
      title: fm({ id: "values-2" }),
      desc: fm({ id: "values-2-desc" }),
    },
    {
      title: fm({ id: "values-2" }),
      desc: fm({ id: "values-2-desc" }),
    },
    {
      title: fm({ id: "values-2" }),
      desc: fm({ id: "values-2-desc" }),
    },
  ]);
  return (
    <>
      <SEO title={fm({ id: "menu-contact" })} />
      <Layout>
        <div>
          <div className="relative z-40">
            <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full flex items-center justify-center transform scale-100">
              <StaticImage
                src="../images/contact us.png"
                alt=""
                className="w-full h-full object-cover"
              />
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60"></div>
            </div>
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60"></div>
            <div className="container relative mx-auto px-4 h-screen flex items-center justify-center text-white">
              <p
                className="text-xl font-cool w-full text-center lg:w-2/3 lg:text-5xl text-white"
                data-sal="slide-up"
                data-dal-delay="1000"
                data-sal-duration={2000}
              >
                {fm({ id: "privacy-title" })}
              </p>
            </div>
          </div>
          <div className="min-h-screen w-full overflow-hidden flex flex-col relative justify-center bg-white">
            {/* <div className="bg-c1 bg-opacity-50 w-screen transform translate-x-3/4 h-screen  rounded-full absolute right-0 bottom-0 top-0"></div> */}
            <div className="bg-c1 bg-opacity-50 w-full lg:w-screen transform lg:-translate-x-3/4 h-screen  rounded-full absolute left-0 bottom-0 top-0"></div>

            <div className="container px-4 relative mx-auto">
              <div className="flex flex-col lg:flex-row items-center">
                <div className="w-2/12 hidden lg:block text-center">
                  <div className="inline-flex">
                    <div className="border-r-8 mx-auto bg-opacity-50 border-main border-opacity-50 w-1 mr-1 h-screen"></div>
                    <div className="border-r-8 mx-auto bg-opacity-50 border-main border-opacity-80 w-1 mr-1 h-screen"></div>
                    <div className="border-r-8 mx-auto bg-opacity-50 border-main border-opacity-100 w-1 mr-1 h-screen"></div>
                  </div>
                </div>
                <p
                  className="w-full lg:w-full text-md space-y-5 py-20"
                  //   data-sal="slide-up"
                  //   data-dal-delay="1000"
                  //   data-sal-duration={2000}
                >
                  <div>
                    Энэхүү нууцлалын бодлого нь STEPPELINK HOLDING вэбсайтад
                    зориулагдсан бөгөөд зөвхөн уг сайтад хэрэглэгдэнэ. Уг
                    нууцлалын бодлого нь хувь хүний вэбийн хэрэглээтэй холбоотой
                    хувийн мэдээллийг ашиглах болон цаашдын бодлогыг
                    хэрэгжүүлэхэд оршино. Та хувийн нууцлалын бодлогын
                    шаардлагуудтай танилцан, шаардлагуудыг хүлээн зөвшөөрснөөр
                    вэб сайтыг ашиглах боломжтой юм. Энэхүү нууцлалын бодлогыг
                    таны хэрэглээнд тохируулан STEPPELINK HOLDING –ний вэб
                    сайтыг ашиглах нөхцөл, тайлбар болон нууцлалтай нэгтгэн байх
                    бөгөөд энэхүү нууцлалын бодлого нь хувийн мэдээлэл
                    алдагдахаас зайлсхийхэд чиглэсэн байна.
                  </div>
                  <div>
                    Ямар нөхцөл, шаардлагаар бид таны хувийн мэдээллийг
                    цуглуулах вэ?
                  </div>
                  <div>Хувийн мэдээлэл:</div>
                  <div>
                    Та бидэнд цахим шуудан илгээх, үйлчилгээтэй холбоотой журам,
                    заавар илгээх, компаний дотоод судалгаа хийх, вэб сайтын
                    “Холбоо барих” хэсэгт хандсанаар бид таны нэр, хаяг, утасны
                    дугаар, цахим хаяг болон бусад мэдээ мэдээллийг олж авах
                    боломжтой. Та бидэнд овог, нэр, төрсөн он сар өдөр, утасны
                    дугаар, цахим шуудангийн хаяг зэрэг системд нэвтрэхтэй
                    холбоотой мэдээллийг багтаасан хувийн мэдээллээ явуулснаар
                    цахим хэлбэр бүхий хувийн хэрэг үүсгэх юм. Уг хувийн хэрэгт
                    таны худалдан авсан үйлчилгээний явцтай холбоотой мэдээллүүд
                    харагдана.
                  </div>
                  <div>Техникийн мэдээлэл:</div>
                  <div>
                    Та вэбсайтэд зочлоход техникээр бус харин таны нэрээр бид
                    таныг тодорхойлох юм. Тус мэдээлэлд дараах зүйлсийг
                    хамруулна. Үүнд: IP хаяг, сайтын аль хэсгээр зочилсон,
                    интернэт ашиглан ямар сайтуудад зочилсон болон ямар броусер
                    ашиглан сайтад зочилж байгаа зэргийг хамруулна. Энэ
                    техникийн мэдээ нь сайтын үр ашигтай байдлыг тодорхойлж,
                    хэмжихэд хэрэглэгдэхээс гадна, сервэрт тулгарч буй асуудлыг
                    шийдвэрлэх, сайтыг хянах мөн хэрэглэгчдийн хандалтын сувгийг
                    тодорхойлоход ашиглагдана. Бид зарим сайтын хэрэглээ болон
                    үзүүлэлт зэрэг хувийн бус мэдээллийг тус сайтаас хүлээн авч,
                    зочилсон хэрэглэгчдийн тоо болон бусад өгөгдлийг мэдэж авах
                    боломжтой.
                  </div>
                  <div>Хувийн мэдээллийн хэрэглээ:</div>
                  <div>
                    Бид хувийн мэдээллийг энэхүү сайтаар дамжуулан бизнесийн
                    болон энд дурдаагүй олон төрлийн зорилгоор цуглуулдаг.
                    Хувийн мэдээллийг зөвхөн таны STEPPELINK HOLDING –аас авахыг
                    хүссэн тодорхой үйлчилгээнд тохируулан ашиглах болно.
                  </div>
                  <div>Хамгаалалт:</div>
                  <div>
                    ∙ Олон улсын стандартыг хангасан технологийн тусламжтайгаар
                    таны хувийн мэдээлэл алдагдах, буруугаар ашиглагдах, өөрчлөх
                    эсвэл устгах зэргээс хамгаалж байна. Бүх ажилтан, албан
                    хаагчид болон удирдагчид хувийн мэдээллийг итгэмжлэл болон
                    зөвхөн зөвшөөрөл дээр үндэслэн хэрэглэдэг. <br />∙
                    Интэрнетийн сүлжээгээр та мэдээллээ бидэнд илгээхэд таны
                    зөвшөөрөл бүхий найдвартай мэдээлэл бидэнд ирж байгаа болон,
                    манай сервэрд хадгалагдсан мэдээллүүдийн аюулгүй байдлыг бид
                    хангаж чадахгүйд хүрэх юм. Харин та манай сайтыг ашигласнаар
                    тус эрсдэлээс зайлсхийх боломжийг бүрдүүлдэг. <br />∙ Бид
                    таны хувийн мэдээллийн аюулгүй байдал болон хяналт алдагдсан
                    байж болзошгүй гэж үзсэн тохиолдолд танд мэдэгддэг. Хэрэв
                    ийм тохиолдол гарвал бид таны хувийн цахим хаяг руу аль
                    болох хурдан хугацаанд мэдэгдэл явуулах болно.
                  </div>
                  <div>
                    STEPPELINK HOLDING нь таны хувийн мэдээллийн үнэн зөв
                    бодитой байдлыг баталгаажуулах ямар нэгэн хариуцлага
                    хүлээхгүй. Хэрэв та хувийн мэдээллийнхээ алдаатай буруу
                    мэдээлэлд засвар хийх гэж байгаа бол STEPPELINK HOLDING-д
                    мэдэгдэх шаардлагатай.
                  </div>
                  <div>Гуравдагч тал:</div>
                  <div>
                    Бид гуравдагч талын цуглуулсан мэдээлэл болон бусад
                    асуудалтай холбоотой ямар нэгэн үүрэг хариуцлага хүлээхгүй
                    болно.
                  </div>
                  <div>Сайт ашиглах шаардлагууд:</div>
                  <div>
                    Та Монгол улсын албан ёсны оршин суугч байх шаардлагатай.
                    Мөн та нууцлалын бодлого нь монгол улсын хуулийн дагуу
                    бэлтгэгдсэн бөгөөд таны хувийн мэдээллийг хууль эрх зүйн
                    хүрээнд ашиглах болно гэдгийг хүлээн зөвшөөрсөн байх
                    шаардлагатай.
                  </div>
                  <div>Мэдээллийн хяналт:</div>
                  <div>
                    STEPPELINK HOLDING нь таны хувийн мэдээллийг STEPPELINK
                    HOLDING бусад олон улсын сүлжээ компаниудад дамжуулан
                    ашиглуулахгүй болно.
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PrivacyPolicy;
